@import "public/scss/_variables";

.footer-container {
    padding: 1.5rem 10%;
    background-color: $baseBlack;


    .footer-parent-container {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-bottom: 1rem;
        gap: 4rem;
        @media only screen and (max-width: $mobileWidth) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
          }
    }

    .footer-socials-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2rem;

        .footer-socials-icon {
            color: $baseWhite;
            transition: .1s all ease-in-out;
            &:hover {
                cursor: pointer;
                scale: 1.2;
            }
        }
    }
    
    .footer-copyright {
        color: $baseWhite;
        text-align: center;
        padding-top: 1rem;
        @media only screen and (max-width: $mobileWidth) {
            font-size: $text-sm-font-size;
          }
    }
}