$baseBlack: #000000;
$baseWhite: #FFFFFF;

$blue100: #d9ecf7;
$blue200: #a0d1ec;
$blue300: #79bee4;
$blue400: #53abdc;
$blue500: #40A2D8; // Primary
$blue600: #3a92c2;
$blue700: #2d7197;
$blue800: #20516c;
$blue900: #133141;

$cream: #F0EDCF;


// Gray
$gray25: #FCFCFD;
$gray50: #F9FAFB;
$gray100: #F2F4F7;
$gray200: #EAECF0;
$gray300: #D0D5DD;
$gray400: #98A2B3;
$gray500: #667085; // Primary
$gray600: #475467;
$gray700: #344054;
$gray800: #1D2939;
$gray900: #101828;


$text-sm-font-size: 14px;
$text-md-font-size: 16px;
$text-lg-font-size: 18px;

$mobileWidth: 767px;
$mobileWidthPlus: calc(#{$mobileWidth} + 1px);

$tabletWidth: 1023px;
$tabletWidthPlus: calc(#{$tabletWidth} + 1px);

$smallDesktopWidth: 1366px;
$smallDesktopWidthPlus: calc(#{$smallDesktopWidth} + 1px);

$desktopWidth: 1440px;
$desktopWidthPlus: calc(#{$desktopWidth} + 1px);


$card-box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

$backDrop: var(--p-backdrop, rgba(33, 43, 54, 0.4));