@import "public/scss/_variables";

.footer-link-group-container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    align-items: flex-start;

    .footer-text-colour {
        color: $baseWhite;
    }

    .footer-link-title {
        font-size: 1.2rem;
        font-weight: 600;
    }

    .footer-link-label {
        font-size: 0.9rem;
        font-weight: 400;
        @media only screen and (max-width: $mobileWidth) {
            font-size: $text-md-font-size;
          }
        &-url {
            &:hover {
                cursor: pointer;
                color: $gray200;
            }
        }
    }
}