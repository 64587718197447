@import "public/scss/_variables";

.main-button {
    border: none;
    border-radius: 5px;
    font-weight: 500;
    transition: all 0.3s;
    &-primary {
        background-color: $blue500;
        color: $baseWhite;
        &:hover:enabled {
            background-color: $baseWhite;
            outline: 1px solid $blue500;
            color: $blue500;
            cursor: pointer;

        }

        &-disabled {
            background-color: $blue100;
            cursor: not-allowed;
        }
    }

    &-secondary {
        background-color: $baseWhite;
        color: $blue500;
        border: 1px solid $blue500;
        &:hover:enabled { 
            cursor: pointer;
            background-color: $blue500;
            color: $baseWhite;
        }
        &-disabled {
            background-color: $blue100;
            cursor: not-allowed;
            color: $blue200;
            border: 1px solid $blue200;
        }
    }

    &-small {
        padding: 8px 14px;
        font-size: $text-sm-font-size;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &-medium {
        padding: 8px 18px;
        font-size: $text-md-font-size;
        font-weight: 600;

        @media only screen and (max-width: $tabletWidth) {
            padding: 4px 7px;
            font-size: $text-sm-font-size;
          }
    }

    &-large {
        padding: 12px 20px;
        font-size: $text-lg-font-size;
        font-weight: 600;
        @media only screen and (max-width: $tabletWidth) {
            padding: 4px 7px;
            font-size: $text-sm-font-size;
          }
    }

    &-block {
        width: 100%;
    }
}