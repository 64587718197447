@import "public/scss/_variables";


.contact-us-page {
    background-color: $gray100;
    .contact-us-main-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        gap: 1rem;
    }

    .contact-us-subtitle {
        font-size: 1rem;
        color: $gray500;
        font-weight: 500;
        margin: 0.5rem 0;
    }

    h1 {
        color: $baseBlack;
        margin: 0.5rem 0;
    }

    a,.phone-icon {
        color: $blue500;
    }

    .details-container {
        display: flex;
        flex-direction: column;

        .contact-link {
            text-decoration: none;
            font-weight: 500;
            &:hover {
                cursor: pointer;
                color: $blue300;
            }
        }
    }

    .contact-us-maps {
        border: 1px solid $gray300;
        border-radius: 8px;
        margin: 1rem 0 0.5rem 0;
        @media only screen and (max-width: $mobileWidth) {
            width: auto;
          }
    }
}