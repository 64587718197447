@import "public/scss/_variables";

.header {
    position: sticky;
    top: 0;
    left: 0;
    background-color: $baseWhite;
    z-index: 25;
    border-bottom: 1px solid $gray200;

    .image-logo-container {
        width: 120px;
        height: 70px;
        .image-logo {
            object-position: center;
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }
    }




    .outer-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 5%;
        padding: 10px 0;
        background-color: $baseWhite;
    }

    .menu-item {
        font-size: 1rem;
        font-weight: 500;
        color: black;
        text-decoration: none;
        @media only screen and (max-width: $mobileWidth) {
            font-size: $text-md-font-size;
            color: $baseWhite;
          }
        &:hover {
            color: $blue500;
            transition: all 0.2s ease-in-out;
        }

        &-active {
            color: $blue500;
        }
        
    }

    .phone-number {
        text-decoration: none;
        font-size: 1.1rem;
        font-weight: 500;
        color: $baseBlack;
        @media only screen and (max-width: $mobileWidth) {
            color: $baseWhite;
            font-size: $text-md-font-size;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
    }   

    .phone-icon {
        color: $blue500;
        @media only screen and (max-width: $mobileWidth) {
            font-size: 1.2rem;
          }
    }

    .options-menu-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        @media only screen and (max-width: $mobileWidth) {
            gap: 0.5rem;
          }

        .phone-number {
            text-decoration: none;
            font-size: 1.1rem;
            font-weight: 500;
            color: $baseBlack;
            @media only screen and (max-width: $mobileWidth) {
                font-size: $text-sm-font-size;
              }
        }   

        .phone-icon {
            color: $blue500;
            @media only screen and (max-width: $mobileWidth) {
                font-size: 2rem;
              }
        }
    }

    .header-mobile-menu {
        &-icon {
            color: $blue500;
        }

        .mobile-menu-container {
            position: fixed;
            top: 0;
            right: 0;
            width: 0%;
            height: 100%;
            background-color: $blue900;
            z-index: 50;
            transition: width 0.4s ease-in-out;
        }

        .mobile-menu-backdrop {
            position: fixed;
            z-index: 40;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $backDrop;
            opacity: 1;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            animation-name: mobile-menu-backdrop;
            animation-duration: 0.4s;
            display: none;
            backdrop-filter: blur(2px);
            
            &-visible {
                display: block;
            }
        }

        .mobile-menu-inner-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            transition: opacity 0.6s ease-in-out;
            opacity: 1;
            &-hidden {
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }
        }

        .mobile-menu-close-button {
            font-size: 2rem;
            color: $baseWhite;
        }

        .mobile-menu-header-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px;
            align-items: center;
        }

        .mobile-menu-options-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 10px;

            .option-header {
                color: $baseWhite;
                font-weight: 600;
                font-size: 18px;
            }
        }

        .mobile-menu-footer-container {
            padding: 10px;

            .mobile-menu-socials-container {
                display: flex;
                align-items: center;
                justify-content: space-around;
                padding: 2rem 1rem;

                .socials-icon {
                    color: $baseWhite;
                    font-size: 2rem;
                }
            }

            .mobile-menu-copyright {
                color: $baseWhite;
                font-size: 10px;
                text-align: center;
                padding: 5px 5px 0 5px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
 
    }
}

@keyframes mobile-menu-backdrop {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }